// #webksde-TF: Upgraded to have a reduced width for the text-content
.marketing-site-content-section {
  display: flex;
  flex-wrap: wrap;
  background: $light-gray;
  @include breakpoint(large){
    display: grid;
    grid-template-columns: 1fr ($global-width / 2) ($global-width / 2) 1fr;
  }
  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .marketing-site-content-section-img {
    align-self: center;
    flex: 1 0 100%;
    @include breakpoint(large){
      align-self: stretch;
      grid-column: 1/3;
    }
    &:nth-child(2){
      @include breakpoint(large){
        grid-column: 3/5;
      }
    }
    img {
      width: 100%;
      height:100%;
      object-fit:cover;
      object-position: center;
    }

    @media screen and (min-width: 40em) {
      flex: 1 0 50%;
    }
  }

  .marketing-site-content-section-block {
    padding: 1rem;
    flex: 1 0 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media screen and (min-width: 40em) {
      flex: 1 0 50%;
      padding: 2rem;
    }
    @include breakpoint(large){
      grid-column: 3;
    }
    &:nth-child(1){
      @include breakpoint(large){
        grid-column: 2;
      }
    }
  }
}

.marketing-site-content-section-block-header {
  font-size: 24px;
}

.marketing-site-content-section-block-subheader {
  font-size: 13px;
}
