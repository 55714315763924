$highlight: #1d92cf;

.social-links {
  background: $highlight;
  padding: 2rem 0 1rem;

  a {
    color: white;
    font-size: 1.2rem;
  }

  i.fa {
    font-size: 1.4rem;
    padding-right: 0.8rem;
    padding-bottom: 1rem;
  }

  @include breakpoint (small only) {
    padding: 2rem 0;

    i.fa {
      font-size: 2rem;
      display: block;
      padding-right: 0;
      padding-bottom: 0.5rem;
    }

    a {
      font-weight: bold;
    }

    .columns {
      margin: 1rem 0;
    }

    .mobile-stack {
      display: flex;
      flex-direction: column;
    }
  }
}
