$product-feature-section-large-width: 75%;
$product-feature-section-bg: $light-gray;
$product-feature-section-divider-color: $medium-gray;

.product-feature-section {
  background: $product-feature-section-bg;
  padding: 1rem;
}

.product-feature-section-outer {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;

  @include breakpoint(medium) {
    max-width: $product-feature-section-large-width;
  }
}

.product-feature-section-headline {
  margin-bottom: 2rem;
  text-align: center;
}

.product-feature-section-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-feature-section-feature {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  flex: 1 0 50%;
  justify-content: flex-start;
  flex-direction: column;

  &.top-left {
    border-right: 1px solid $product-feature-section-divider-color;
    border-bottom: 1px solid $product-feature-section-divider-color;
  }

  &.top-right {
    border-bottom: 1px solid $product-feature-section-divider-color;
  }

  &.bottom-left {
    border-right: 1px solid $product-feature-section-divider-color;
  }

  .feature-title {
    margin-bottom: 0;
    line-height: 16px;
    font-size: 0.875rem;
  }

  .feature-desc {
    margin-bottom: 0;
  }

  .fa {
    margin-right: 1.25rem;
    font-size: 2rem;
  }

  @include breakpoint(medium) {
    padding: 2rem;
    flex: 1 0 50%;
    justify-content: flex-start;
    flex-direction: row;
  }
}
