.pricing-table {
  background-color: $white;
  border: solid 1px $medium-gray;
  width: 100%;
  text-align: center;
  list-style-type: none;

  li {
    border-bottom: dotted 1px $medium-gray;
    padding: 0.875rem 1.125rem;

    &:last-child {
      border-bottom: 0;
    }
  }

  .title {
    background-color: $black;
    color: $white;
    border-bottom: 0;
  }

  .price {
    background-color: $light-gray;
    font-size: 2rem;
    border-bottom: 0;
  }

  .description {
    color: $dark-gray;
    font-size: $small-font-size;
  }

  :last-child {
    margin-bottom: 0;
  }
}
