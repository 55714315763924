:root{
  --space-sm: .5rem;
  --space-md: .75rem;
  --space-lg: 1.5rem;
  --space-xl: 3rem;
  @include breakpoint(large){
    --space-md: 1rem;
    --space-lg: 3rem;
    --space-xl: 6rem;
  }
}

// Margins
.space-lg{
  margin: var(--space-lg);
}
.space-left-sm{
  margin-left: var(--space-sm);
}
.space-top-sm{
  margin-top: var(--space-sm);
}
.space-right-sm{
  margin-right: var(--space-sm);
}
.space-bottom-sm{
  margin-bottom: var(--space-sm);
}

.space-left-md{
  margin-left: var(--space-md);
}
.space-top-md{
  margin-top: var(--space-md);
}
.space-right-md{
  margin-right: var(--space-md);
}
.space-bottom-md{
  margin-bottom: var(--space-md);
}

.space-left-lg{
  margin-left: var(--space-lg);
}
.space-top-lg{
  margin-top: var(--space-lg);
}
.space-right-lg{
  margin-right: var(--space-lg);
}
.space-bottom-lg{
  margin-bottom: var(--space-lg);
}

.space-left-xl{
  margin-left: var(--space-xl);
}
.space-top-xl{
  margin-top: var(--space-xl);
}
.space-right-xl{
  margin-right: var(--space-xl);
}
.space-bottom-xl{
  margin-bottom: var(--space-xl);
}

// Paddings
.space-inner-lg{
  padding: var(--space-lg);
}
.space-inner-left-sm{
  padding-left: var(--space-sm);
}
.space-inner-top-sm{
  padding-top: var(--space-sm);
}
.space-inner-right-sm{
  padding-right: var(--space-sm);
}
.space-inner-bottom-sm{
  padding-bottom: var(--space-sm);
}

.space-inner-left-md{
  padding-left: var(--space-md);
}
.space-inner-top-md{
  padding-top: var(--space-md);
}
.space-inner-right-md{
  padding-right: var(--space-md);
}
.space-inner-bottom-md{
  padding-bottom: var(--space-md);
}

.space-inner-left-lg{
  padding-left: var(--space-lg);
}
.space-inner-top-lg{
  padding-top: var(--space-lg);
}
.space-inner-right-lg{
  padding-right: var(--space-lg);
}
.space-inner-bottom-lg{
  padding-bottom: var(--space-lg);
}

.space-inner-left-xl{
  padding-left: var(--space-xl);
}
.space-inner-top-xl{
  padding-top: var(--space-xl);
}
.space-inner-right-xl{
  padding-right: var(--space-xl);
}
.space-inner-bottom-xl{
  padding-bottom: var(--space-xl);
}
