.app-feature-section {
  background-color: $light-gray;
  padding: $global-padding;

  @include breakpoint(medium) {
    padding: $global-padding*2;
  }
}

.app-feature-section-main-header {
  font-weight: 700;

  @include breakpoint(medium down) {
    font-size: 30px;
  }
}

.app-feature-section-sub-header {
  font-weight: 300;
  margin-bottom: 0;

  @include breakpoint(medium down) {
    font-size: 24px;
  }
}

.app-feature-section-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.app-feature-section-features-block {
  display: flex;
  align-items: center;
  padding: $global-padding 0;
  flex: 1 0 33.33%;
  font-size: 20px;
  white-space: nowrap;

  .fa {
    margin-right: 1rem;
    font-size: 35px;
  }

  @include breakpoint(small only) {
    flex: 1 0 50%;
    justify-content: center;
  }

  @media screen and (max-width: 25.9375em) {
    flex: 1 0 100%;
    justify-content: center;
  }
}
