// Generate color classes from foundations color palette
@each $name, $color in $foundation-palette {
  .text-color-#{$name} { color:$color !important; }
}
@each $name, $color in $foundation-palette {
  .bg-color-#{$name} { background-color:$color !important; }
}

// Contextual fixes
// -- Fix link color if color is overridden by class
[class*="text-color-"]{
  a:not(.button){
    color: currentColor;
  }
}
