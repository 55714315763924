$marketing-site-hero-height: 65vh;

.marketing-site-hero {
  background: url('https://images.pexels.com/photos/8264/pexels-photo.jpg?h=350&auto=compress&cs=tinysrgb') top right no-repeat;
  height: $marketing-site-hero-height;
  background-size: cover;
  display: flex;
  align-items: center;

  @media screen and (min-width: 40em) {
    background-position: center center;
  }
}

// #webksde-TF: Fixed the nonsense default positioning of the overlay box
.marketing-site-hero-content {
  width:100%;
  max-width: ($global-width * 1.5);
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
  display:flex;
  justify-content: flex-end;

  h1 {
    font-size: 32px;
  }

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0;
  }
}
.marketing-site-hero-content-box{
  background: rgba(10, 10, 10, 0.45);
  color: $white;
  padding:2rem;
  @media screen and (min-width: 40em) {
    max-width:500px;
  }
}
