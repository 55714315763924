@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?b8b0gg') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?b8b0gg') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?b8b0gg') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?b8b0gg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}

.fa {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-asterisk {
  &:before {
    content: $fa-asterisk;
  }
}
.fa-plus {
  &:before {
    content: $fa-plus;
  }
}
.fa-question {
  &:before {
    content: $fa-question;
  }
}
.fa-minus {
  &:before {
    content: $fa-minus;
  }
}
.fa-glass {
  &:before {
    content: $fa-glass;
  }
}
.fa-music {
  &:before {
    content: $fa-music;
  }
}
.fa-search {
  &:before {
    content: $fa-search;
  }
}
.fa-envelope-o {
  &:before {
    content: $fa-envelope-o;
  }
}
.fa-heart {
  &:before {
    content: $fa-heart;
  }
}
.fa-star {
  &:before {
    content: $fa-star;
  }
}
.fa-star-o {
  &:before {
    content: $fa-star-o;
  }
}
.fa-user {
  &:before {
    content: $fa-user;
  }
}
.fa-film {
  &:before {
    content: $fa-film;
  }
}
.fa-th-large {
  &:before {
    content: $fa-th-large;
  }
}
.fa-th {
  &:before {
    content: $fa-th;
  }
}
.fa-th-list {
  &:before {
    content: $fa-th-list;
  }
}
.fa-check {
  &:before {
    content: $fa-check;
  }
}
.fa-close {
  &:before {
    content: $fa-close;
  }
}
.fa-remove {
  &:before {
    content: $fa-remove;
  }
}
.fa-times {
  &:before {
    content: $fa-times;
  }
}
.fa-search-plus {
  &:before {
    content: $fa-search-plus;
  }
}
.fa-search-minus {
  &:before {
    content: $fa-search-minus;
  }
}
.fa-power-off {
  &:before {
    content: $fa-power-off;
  }
}
.fa-signal {
  &:before {
    content: $fa-signal;
  }
}
.fa-cog {
  &:before {
    content: $fa-cog;
  }
}
.fa-gear {
  &:before {
    content: $fa-gear;
  }
}
.fa-trash-o {
  &:before {
    content: $fa-trash-o;
  }
}
.fa-home {
  &:before {
    content: $fa-home;
  }
}
.fa-file-o {
  &:before {
    content: $fa-file-o;
  }
}
.fa-clock-o {
  &:before {
    content: $fa-clock-o;
  }
}
.fa-road {
  &:before {
    content: $fa-road;
  }
}
.fa-download {
  &:before {
    content: $fa-download;
  }
}
.fa-arrow-circle-o-down {
  &:before {
    content: $fa-arrow-circle-o-down;
  }
}
.fa-arrow-circle-o-up {
  &:before {
    content: $fa-arrow-circle-o-up;
  }
}
.fa-inbox {
  &:before {
    content: $fa-inbox;
  }
}
.fa-play-circle-o {
  &:before {
    content: $fa-play-circle-o;
  }
}
.fa-repeat {
  &:before {
    content: $fa-repeat;
  }
}
.fa-rotate-right {
  &:before {
    content: $fa-rotate-right;
  }
}
.fa-refresh {
  &:before {
    content: $fa-refresh;
  }
}
.fa-list-alt {
  &:before {
    content: $fa-list-alt;
  }
}
.fa-lock {
  &:before {
    content: $fa-lock;
  }
}
.fa-flag {
  &:before {
    content: $fa-flag;
  }
}
.fa-headphones {
  &:before {
    content: $fa-headphones;
  }
}
.fa-volume-off {
  &:before {
    content: $fa-volume-off;
  }
}
.fa-volume-down {
  &:before {
    content: $fa-volume-down;
  }
}
.fa-volume-up {
  &:before {
    content: $fa-volume-up;
  }
}
.fa-qrcode {
  &:before {
    content: $fa-qrcode;
  }
}
.fa-barcode {
  &:before {
    content: $fa-barcode;
  }
}
.fa-tag {
  &:before {
    content: $fa-tag;
  }
}
.fa-tags {
  &:before {
    content: $fa-tags;
  }
}
.fa-book {
  &:before {
    content: $fa-book;
  }
}
.fa-bookmark {
  &:before {
    content: $fa-bookmark;
  }
}
.fa-print {
  &:before {
    content: $fa-print;
  }
}
.fa-camera {
  &:before {
    content: $fa-camera;
  }
}
.fa-font {
  &:before {
    content: $fa-font;
  }
}
.fa-bold {
  &:before {
    content: $fa-bold;
  }
}
.fa-italic {
  &:before {
    content: $fa-italic;
  }
}
.fa-text-height {
  &:before {
    content: $fa-text-height;
  }
}
.fa-text-width {
  &:before {
    content: $fa-text-width;
  }
}
.fa-align-left {
  &:before {
    content: $fa-align-left;
  }
}
.fa-align-center {
  &:before {
    content: $fa-align-center;
  }
}
.fa-align-right {
  &:before {
    content: $fa-align-right;
  }
}
.fa-align-justify {
  &:before {
    content: $fa-align-justify;
  }
}
.fa-list {
  &:before {
    content: $fa-list;
  }
}
.fa-dedent {
  &:before {
    content: $fa-dedent;
  }
}
.fa-outdent {
  &:before {
    content: $fa-outdent;
  }
}
.fa-indent {
  &:before {
    content: $fa-indent;
  }
}
.fa-video-camera {
  &:before {
    content: $fa-video-camera;
  }
}
.fa-image {
  &:before {
    content: $fa-image;
  }
}
.fa-photo {
  &:before {
    content: $fa-photo;
  }
}
.fa-picture-o {
  &:before {
    content: $fa-picture-o;
  }
}
.fa-pencil {
  &:before {
    content: $fa-pencil;
  }
}
.fa-map-marker {
  &:before {
    content: $fa-map-marker;
  }
}
.fa-adjust {
  &:before {
    content: $fa-adjust;
  }
}
.fa-tint {
  &:before {
    content: $fa-tint;
  }
}
.fa-edit {
  &:before {
    content: $fa-edit;
  }
}
.fa-pencil-square-o {
  &:before {
    content: $fa-pencil-square-o;
  }
}
.fa-share-square-o {
  &:before {
    content: $fa-share-square-o;
  }
}
.fa-check-square-o {
  &:before {
    content: $fa-check-square-o;
  }
}
.fa-arrows {
  &:before {
    content: $fa-arrows;
  }
}
.fa-step-backward {
  &:before {
    content: $fa-step-backward;
  }
}
.fa-fast-backward {
  &:before {
    content: $fa-fast-backward;
  }
}
.fa-backward {
  &:before {
    content: $fa-backward;
  }
}
.fa-play {
  &:before {
    content: $fa-play;
  }
}
.fa-pause {
  &:before {
    content: $fa-pause;
  }
}
.fa-stop {
  &:before {
    content: $fa-stop;
  }
}
.fa-forward {
  &:before {
    content: $fa-forward;
  }
}
.fa-fast-forward {
  &:before {
    content: $fa-fast-forward;
  }
}
.fa-step-forward {
  &:before {
    content: $fa-step-forward;
  }
}
.fa-eject {
  &:before {
    content: $fa-eject;
  }
}
.fa-chevron-left {
  &:before {
    content: $fa-chevron-left;
  }
}
.fa-chevron-right {
  &:before {
    content: $fa-chevron-right;
  }
}
.fa-plus-circle {
  &:before {
    content: $fa-plus-circle;
  }
}
.fa-minus-circle {
  &:before {
    content: $fa-minus-circle;
  }
}
.fa-times-circle {
  &:before {
    content: $fa-times-circle;
  }
}
.fa-check-circle {
  &:before {
    content: $fa-check-circle;
  }
}
.fa-question-circle {
  &:before {
    content: $fa-question-circle;
  }
}
.fa-info-circle {
  &:before {
    content: $fa-info-circle;
  }
}
.fa-crosshairs {
  &:before {
    content: $fa-crosshairs;
  }
}
.fa-times-circle-o {
  &:before {
    content: $fa-times-circle-o;
  }
}
.fa-check-circle-o {
  &:before {
    content: $fa-check-circle-o;
  }
}
.fa-ban {
  &:before {
    content: $fa-ban;
  }
}
.fa-arrow-left {
  &:before {
    content: $fa-arrow-left;
  }
}
.fa-arrow-right {
  &:before {
    content: $fa-arrow-right;
  }
}
.fa-arrow-up {
  &:before {
    content: $fa-arrow-up;
  }
}
.fa-arrow-down {
  &:before {
    content: $fa-arrow-down;
  }
}
.fa-mail-forward {
  &:before {
    content: $fa-mail-forward;
  }
}
.fa-share {
  &:before {
    content: $fa-share;
  }
}
.fa-expand {
  &:before {
    content: $fa-expand;
  }
}
.fa-compress {
  &:before {
    content: $fa-compress;
  }
}
.fa-exclamation-circle {
  &:before {
    content: $fa-exclamation-circle;
  }
}
.fa-gift {
  &:before {
    content: $fa-gift;
  }
}
.fa-leaf {
  &:before {
    content: $fa-leaf;
  }
}
.fa-fire {
  &:before {
    content: $fa-fire;
  }
}
.fa-eye {
  &:before {
    content: $fa-eye;
  }
}
.fa-eye-slash {
  &:before {
    content: $fa-eye-slash;
  }
}
.fa-exclamation-triangle {
  &:before {
    content: $fa-exclamation-triangle;
  }
}
.fa-warning {
  &:before {
    content: $fa-warning;
  }
}
.fa-plane {
  &:before {
    content: $fa-plane;
  }
}
.fa-calendar {
  &:before {
    content: $fa-calendar;
  }
}
.fa-random {
  &:before {
    content: $fa-random;
  }
}
.fa-comment {
  &:before {
    content: $fa-comment;
  }
}
.fa-magnet {
  &:before {
    content: $fa-magnet;
  }
}
.fa-chevron-up {
  &:before {
    content: $fa-chevron-up;
  }
}
.fa-chevron-down {
  &:before {
    content: $fa-chevron-down;
  }
}
.fa-retweet {
  &:before {
    content: $fa-retweet;
  }
}
.fa-shopping-cart {
  &:before {
    content: $fa-shopping-cart;
  }
}
.fa-folder {
  &:before {
    content: $fa-folder;
  }
}
.fa-folder-open {
  &:before {
    content: $fa-folder-open;
  }
}
.fa-arrows-v {
  &:before {
    content: $fa-arrows-v;
  }
}
.fa-arrows-h {
  &:before {
    content: $fa-arrows-h;
  }
}
.fa-bar-chart {
  &:before {
    content: $fa-bar-chart;
  }
}
.fa-bar-chart-o {
  &:before {
    content: $fa-bar-chart-o;
  }
}
.fa-twitter-square {
  &:before {
    content: $fa-twitter-square;
  }
}
.fa-facebook-square {
  &:before {
    content: $fa-facebook-square;
  }
}
.fa-camera-retro {
  &:before {
    content: $fa-camera-retro;
  }
}
.fa-key {
  &:before {
    content: $fa-key;
  }
}
.fa-cogs {
  &:before {
    content: $fa-cogs;
  }
}
.fa-gears {
  &:before {
    content: $fa-gears;
  }
}
.fa-comments {
  &:before {
    content: $fa-comments;
  }
}
.fa-thumbs-o-up {
  &:before {
    content: $fa-thumbs-o-up;
  }
}
.fa-thumbs-o-down {
  &:before {
    content: $fa-thumbs-o-down;
  }
}
.fa-star-half {
  &:before {
    content: $fa-star-half;
  }
}
.fa-heart-o {
  &:before {
    content: $fa-heart-o;
  }
}
.fa-sign-out {
  &:before {
    content: $fa-sign-out;
  }
}
.fa-linkedin-square {
  &:before {
    content: $fa-linkedin-square;
  }
}
.fa-thumb-tack {
  &:before {
    content: $fa-thumb-tack;
  }
}
.fa-external-link {
  &:before {
    content: $fa-external-link;
  }
}
.fa-sign-in {
  &:before {
    content: $fa-sign-in;
  }
}
.fa-trophy {
  &:before {
    content: $fa-trophy;
  }
}
.fa-github-square {
  &:before {
    content: $fa-github-square;
  }
}
.fa-upload {
  &:before {
    content: $fa-upload;
  }
}
.fa-lemon-o {
  &:before {
    content: $fa-lemon-o;
  }
}
.fa-phone {
  &:before {
    content: $fa-phone;
  }
}
.fa-square-o {
  &:before {
    content: $fa-square-o;
  }
}
.fa-bookmark-o {
  &:before {
    content: $fa-bookmark-o;
  }
}
.fa-phone-square {
  &:before {
    content: $fa-phone-square;
  }
}
.fa-twitter {
  &:before {
    content: $fa-twitter;
  }
}
.fa-facebook {
  &:before {
    content: $fa-facebook;
  }
}
.fa-facebook-f {
  &:before {
    content: $fa-facebook-f;
  }
}
.fa-github {
  &:before {
    content: $fa-github;
  }
}
.fa-unlock {
  &:before {
    content: $fa-unlock;
  }
}
.fa-credit-card {
  &:before {
    content: $fa-credit-card;
  }
}
.fa-feed {
  &:before {
    content: $fa-feed;
  }
}
.fa-rss {
  &:before {
    content: $fa-rss;
  }
}
.fa-hdd-o {
  &:before {
    content: $fa-hdd-o;
  }
}
.fa-bullhorn {
  &:before {
    content: $fa-bullhorn;
  }
}
.fa-bell-o {
  &:before {
    content: $fa-bell-o;
  }
}
.fa-certificate {
  &:before {
    content: $fa-certificate;
  }
}
.fa-hand-o-right {
  &:before {
    content: $fa-hand-o-right;
  }
}
.fa-hand-o-left {
  &:before {
    content: $fa-hand-o-left;
  }
}
.fa-hand-o-up {
  &:before {
    content: $fa-hand-o-up;
  }
}
.fa-hand-o-down {
  &:before {
    content: $fa-hand-o-down;
  }
}
.fa-arrow-circle-left {
  &:before {
    content: $fa-arrow-circle-left;
  }
}
.fa-arrow-circle-right {
  &:before {
    content: $fa-arrow-circle-right;
  }
}
.fa-arrow-circle-up {
  &:before {
    content: $fa-arrow-circle-up;
  }
}
.fa-arrow-circle-down {
  &:before {
    content: $fa-arrow-circle-down;
  }
}
.fa-globe {
  &:before {
    content: $fa-globe;
  }
}
.fa-wrench {
  &:before {
    content: $fa-wrench;
  }
}
.fa-tasks {
  &:before {
    content: $fa-tasks;
  }
}
.fa-filter {
  &:before {
    content: $fa-filter;
  }
}
.fa-briefcase {
  &:before {
    content: $fa-briefcase;
  }
}
.fa-arrows-alt {
  &:before {
    content: $fa-arrows-alt;
  }
}
.fa-group {
  &:before {
    content: $fa-group;
  }
}
.fa-users {
  &:before {
    content: $fa-users;
  }
}
.fa-chain {
  &:before {
    content: $fa-chain;
  }
}
.fa-link {
  &:before {
    content: $fa-link;
  }
}
.fa-cloud {
  &:before {
    content: $fa-cloud;
  }
}
.fa-flask {
  &:before {
    content: $fa-flask;
  }
}
.fa-cut {
  &:before {
    content: $fa-cut;
  }
}
.fa-scissors {
  &:before {
    content: $fa-scissors;
  }
}
.fa-copy {
  &:before {
    content: $fa-copy;
  }
}
.fa-files-o {
  &:before {
    content: $fa-files-o;
  }
}
.fa-paperclip {
  &:before {
    content: $fa-paperclip;
  }
}
.fa-floppy-o {
  &:before {
    content: $fa-floppy-o;
  }
}
.fa-save {
  &:before {
    content: $fa-save;
  }
}
.fa-square {
  &:before {
    content: $fa-square;
  }
}
.fa-bars {
  &:before {
    content: $fa-bars;
  }
}
.fa-navicon {
  &:before {
    content: $fa-navicon;
  }
}
.fa-reorder {
  &:before {
    content: $fa-reorder;
  }
}
.fa-list-ul {
  &:before {
    content: $fa-list-ul;
  }
}
.fa-list-ol {
  &:before {
    content: $fa-list-ol;
  }
}
.fa-strikethrough {
  &:before {
    content: $fa-strikethrough;
  }
}
.fa-underline {
  &:before {
    content: $fa-underline;
  }
}
.fa-table {
  &:before {
    content: $fa-table;
  }
}
.fa-magic {
  &:before {
    content: $fa-magic;
  }
}
.fa-truck {
  &:before {
    content: $fa-truck;
  }
}
.fa-pinterest {
  &:before {
    content: $fa-pinterest;
  }
}
.fa-pinterest-square {
  &:before {
    content: $fa-pinterest-square;
  }
}
.fa-google-plus-square {
  &:before {
    content: $fa-google-plus-square;
  }
}
.fa-google-plus {
  &:before {
    content: $fa-google-plus;
  }
}
.fa-money {
  &:before {
    content: $fa-money;
  }
}
.fa-caret-down {
  &:before {
    content: $fa-caret-down;
  }
}
.fa-caret-up {
  &:before {
    content: $fa-caret-up;
  }
}
.fa-caret-left {
  &:before {
    content: $fa-caret-left;
  }
}
.fa-caret-right {
  &:before {
    content: $fa-caret-right;
  }
}
.fa-columns {
  &:before {
    content: $fa-columns;
  }
}
.fa-sort {
  &:before {
    content: $fa-sort;
  }
}
.fa-unsorted {
  &:before {
    content: $fa-unsorted;
  }
}
.fa-sort-desc {
  &:before {
    content: $fa-sort-desc;
  }
}
.fa-sort-down {
  &:before {
    content: $fa-sort-down;
  }
}
.fa-sort-asc {
  &:before {
    content: $fa-sort-asc;
  }
}
.fa-sort-up {
  &:before {
    content: $fa-sort-up;
  }
}
.fa-envelope {
  &:before {
    content: $fa-envelope;
  }
}
.fa-linkedin {
  &:before {
    content: $fa-linkedin;
  }
}
.fa-rotate-left {
  &:before {
    content: $fa-rotate-left;
  }
}
.fa-undo {
  &:before {
    content: $fa-undo;
  }
}
.fa-gavel {
  &:before {
    content: $fa-gavel;
  }
}
.fa-legal {
  &:before {
    content: $fa-legal;
  }
}
.fa-dashboard {
  &:before {
    content: $fa-dashboard;
  }
}
.fa-tachometer {
  &:before {
    content: $fa-tachometer;
  }
}
.fa-comment-o {
  &:before {
    content: $fa-comment-o;
  }
}
.fa-comments-o {
  &:before {
    content: $fa-comments-o;
  }
}
.fa-bolt {
  &:before {
    content: $fa-bolt;
  }
}
.fa-flash {
  &:before {
    content: $fa-flash;
  }
}
.fa-sitemap {
  &:before {
    content: $fa-sitemap;
  }
}
.fa-umbrella {
  &:before {
    content: $fa-umbrella;
  }
}
.fa-clipboard {
  &:before {
    content: $fa-clipboard;
  }
}
.fa-paste {
  &:before {
    content: $fa-paste;
  }
}
.fa-lightbulb-o {
  &:before {
    content: $fa-lightbulb-o;
  }
}
.fa-exchange {
  &:before {
    content: $fa-exchange;
  }
}
.fa-cloud-download {
  &:before {
    content: $fa-cloud-download;
  }
}
.fa-cloud-upload {
  &:before {
    content: $fa-cloud-upload;
  }
}
.fa-user-md {
  &:before {
    content: $fa-user-md;
  }
}
.fa-stethoscope {
  &:before {
    content: $fa-stethoscope;
  }
}
.fa-suitcase {
  &:before {
    content: $fa-suitcase;
  }
}
.fa-bell {
  &:before {
    content: $fa-bell;
  }
}
.fa-coffee {
  &:before {
    content: $fa-coffee;
  }
}
.fa-cutlery {
  &:before {
    content: $fa-cutlery;
  }
}
.fa-file-text-o {
  &:before {
    content: $fa-file-text-o;
  }
}
.fa-building-o {
  &:before {
    content: $fa-building-o;
  }
}
.fa-hospital-o {
  &:before {
    content: $fa-hospital-o;
  }
}
.fa-ambulance {
  &:before {
    content: $fa-ambulance;
  }
}
.fa-medkit {
  &:before {
    content: $fa-medkit;
  }
}
.fa-fighter-jet {
  &:before {
    content: $fa-fighter-jet;
  }
}
.fa-beer {
  &:before {
    content: $fa-beer;
  }
}
.fa-h-square {
  &:before {
    content: $fa-h-square;
  }
}
.fa-plus-square {
  &:before {
    content: $fa-plus-square;
  }
}
.fa-angle-double-left {
  &:before {
    content: $fa-angle-double-left;
  }
}
.fa-angle-double-right {
  &:before {
    content: $fa-angle-double-right;
  }
}
.fa-angle-double-up {
  &:before {
    content: $fa-angle-double-up;
  }
}
.fa-angle-double-down {
  &:before {
    content: $fa-angle-double-down;
  }
}
.fa-angle-left {
  &:before {
    content: $fa-angle-left;
  }
}
.fa-angle-right {
  &:before {
    content: $fa-angle-right;
  }
}
.fa-angle-up {
  &:before {
    content: $fa-angle-up;
  }
}
.fa-angle-down {
  &:before {
    content: $fa-angle-down;
  }
}
.fa-desktop {
  &:before {
    content: $fa-desktop;
  }
}
.fa-laptop {
  &:before {
    content: $fa-laptop;
  }
}
.fa-tablet {
  &:before {
    content: $fa-tablet;
  }
}
.fa-mobile {
  &:before {
    content: $fa-mobile;
  }
}
.fa-mobile-phone {
  &:before {
    content: $fa-mobile-phone;
  }
}
.fa-circle-o {
  &:before {
    content: $fa-circle-o;
  }
}
.fa-quote-left {
  &:before {
    content: $fa-quote-left;
  }
}
.fa-quote-right {
  &:before {
    content: $fa-quote-right;
  }
}
.fa-spinner {
  &:before {
    content: $fa-spinner;
  }
}
.fa-circle {
  &:before {
    content: $fa-circle;
  }
}
.fa-mail-reply {
  &:before {
    content: $fa-mail-reply;
  }
}
.fa-reply {
  &:before {
    content: $fa-reply;
  }
}
.fa-github-alt {
  &:before {
    content: $fa-github-alt;
  }
}
.fa-folder-o {
  &:before {
    content: $fa-folder-o;
  }
}
.fa-folder-open-o {
  &:before {
    content: $fa-folder-open-o;
  }
}
.fa-smile-o {
  &:before {
    content: $fa-smile-o;
  }
}
.fa-frown-o {
  &:before {
    content: $fa-frown-o;
  }
}
.fa-meh-o {
  &:before {
    content: $fa-meh-o;
  }
}
.fa-gamepad {
  &:before {
    content: $fa-gamepad;
  }
}
.fa-keyboard-o {
  &:before {
    content: $fa-keyboard-o;
  }
}
.fa-flag-o {
  &:before {
    content: $fa-flag-o;
  }
}
.fa-flag-checkered {
  &:before {
    content: $fa-flag-checkered;
  }
}
.fa-terminal {
  &:before {
    content: $fa-terminal;
  }
}
.fa-code {
  &:before {
    content: $fa-code;
  }
}
.fa-mail-reply-all {
  &:before {
    content: $fa-mail-reply-all;
  }
}
.fa-reply-all {
  &:before {
    content: $fa-reply-all;
  }
}
.fa-star-half-empty {
  &:before {
    content: $fa-star-half-empty;
  }
}
.fa-star-half-full {
  &:before {
    content: $fa-star-half-full;
  }
}
.fa-star-half-o {
  &:before {
    content: $fa-star-half-o;
  }
}
.fa-location-arrow {
  &:before {
    content: $fa-location-arrow;
  }
}
.fa-crop {
  &:before {
    content: $fa-crop;
  }
}
.fa-code-fork {
  &:before {
    content: $fa-code-fork;
  }
}
.fa-chain-broken {
  &:before {
    content: $fa-chain-broken;
  }
}
.fa-unlink {
  &:before {
    content: $fa-unlink;
  }
}
.fa-info {
  &:before {
    content: $fa-info;
  }
}
.fa-exclamation {
  &:before {
    content: $fa-exclamation;
  }
}
.fa-superscript {
  &:before {
    content: $fa-superscript;
  }
}
.fa-subscript {
  &:before {
    content: $fa-subscript;
  }
}
.fa-eraser {
  &:before {
    content: $fa-eraser;
  }
}
.fa-puzzle-piece {
  &:before {
    content: $fa-puzzle-piece;
  }
}
.fa-microphone {
  &:before {
    content: $fa-microphone;
  }
}
.fa-microphone-slash {
  &:before {
    content: $fa-microphone-slash;
  }
}
.fa-shield {
  &:before {
    content: $fa-shield;
  }
}
.fa-calendar-o {
  &:before {
    content: $fa-calendar-o;
  }
}
.fa-fire-extinguisher {
  &:before {
    content: $fa-fire-extinguisher;
  }
}
.fa-rocket {
  &:before {
    content: $fa-rocket;
  }
}
.fa-maxcdn {
  &:before {
    content: $fa-maxcdn;
  }
}
.fa-chevron-circle-left {
  &:before {
    content: $fa-chevron-circle-left;
  }
}
.fa-chevron-circle-right {
  &:before {
    content: $fa-chevron-circle-right;
  }
}
.fa-chevron-circle-up {
  &:before {
    content: $fa-chevron-circle-up;
  }
}
.fa-chevron-circle-down {
  &:before {
    content: $fa-chevron-circle-down;
  }
}
.fa-html5 {
  &:before {
    content: $fa-html5;
  }
}
.fa-css3 {
  &:before {
    content: $fa-css3;
  }
}
.fa-anchor {
  &:before {
    content: $fa-anchor;
  }
}
.fa-unlock-alt {
  &:before {
    content: $fa-unlock-alt;
  }
}
.fa-bullseye {
  &:before {
    content: $fa-bullseye;
  }
}
.fa-ellipsis-h {
  &:before {
    content: $fa-ellipsis-h;
  }
}
.fa-ellipsis-v {
  &:before {
    content: $fa-ellipsis-v;
  }
}
.fa-rss-square {
  &:before {
    content: $fa-rss-square;
  }
}
.fa-play-circle {
  &:before {
    content: $fa-play-circle;
  }
}
.fa-ticket {
  &:before {
    content: $fa-ticket;
  }
}
.fa-minus-square {
  &:before {
    content: $fa-minus-square;
  }
}
.fa-minus-square-o {
  &:before {
    content: $fa-minus-square-o;
  }
}
.fa-level-up {
  &:before {
    content: $fa-level-up;
  }
}
.fa-level-down {
  &:before {
    content: $fa-level-down;
  }
}
.fa-check-square {
  &:before {
    content: $fa-check-square;
  }
}
.fa-pencil-square {
  &:before {
    content: $fa-pencil-square;
  }
}
.fa-external-link-square {
  &:before {
    content: $fa-external-link-square;
  }
}
.fa-share-square {
  &:before {
    content: $fa-share-square;
  }
}
.fa-compass {
  &:before {
    content: $fa-compass;
  }
}
.fa-caret-square-o-down {
  &:before {
    content: $fa-caret-square-o-down;
  }
}
.fa-toggle-down {
  &:before {
    content: $fa-toggle-down;
  }
}
.fa-caret-square-o-up {
  &:before {
    content: $fa-caret-square-o-up;
  }
}
.fa-toggle-up {
  &:before {
    content: $fa-toggle-up;
  }
}
.fa-caret-square-o-right {
  &:before {
    content: $fa-caret-square-o-right;
  }
}
.fa-toggle-right {
  &:before {
    content: $fa-toggle-right;
  }
}
.fa-eur {
  &:before {
    content: $fa-eur;
  }
}
.fa-euro {
  &:before {
    content: $fa-euro;
  }
}
.fa-gbp {
  &:before {
    content: $fa-gbp;
  }
}
.fa-dollar {
  &:before {
    content: $fa-dollar;
  }
}
.fa-usd {
  &:before {
    content: $fa-usd;
  }
}
.fa-inr {
  &:before {
    content: $fa-inr;
  }
}
.fa-rupee {
  &:before {
    content: $fa-rupee;
  }
}
.fa-cny {
  &:before {
    content: $fa-cny;
  }
}
.fa-jpy {
  &:before {
    content: $fa-jpy;
  }
}
.fa-rmb {
  &:before {
    content: $fa-rmb;
  }
}
.fa-yen {
  &:before {
    content: $fa-yen;
  }
}
.fa-rouble {
  &:before {
    content: $fa-rouble;
  }
}
.fa-rub {
  &:before {
    content: $fa-rub;
  }
}
.fa-ruble {
  &:before {
    content: $fa-ruble;
  }
}
.fa-krw {
  &:before {
    content: $fa-krw;
  }
}
.fa-won {
  &:before {
    content: $fa-won;
  }
}
.fa-bitcoin {
  &:before {
    content: $fa-bitcoin;
  }
}
.fa-btc {
  &:before {
    content: $fa-btc;
  }
}
.fa-file {
  &:before {
    content: $fa-file;
  }
}
.fa-file-text {
  &:before {
    content: $fa-file-text;
  }
}
.fa-sort-alpha-asc {
  &:before {
    content: $fa-sort-alpha-asc;
  }
}
.fa-sort-alpha-desc {
  &:before {
    content: $fa-sort-alpha-desc;
  }
}
.fa-sort-amount-asc {
  &:before {
    content: $fa-sort-amount-asc;
  }
}
.fa-sort-amount-desc {
  &:before {
    content: $fa-sort-amount-desc;
  }
}
.fa-sort-numeric-asc {
  &:before {
    content: $fa-sort-numeric-asc;
  }
}
.fa-sort-numeric-desc {
  &:before {
    content: $fa-sort-numeric-desc;
  }
}
.fa-thumbs-up {
  &:before {
    content: $fa-thumbs-up;
  }
}
.fa-thumbs-down {
  &:before {
    content: $fa-thumbs-down;
  }
}
.fa-youtube-square {
  &:before {
    content: $fa-youtube-square;
  }
}
.fa-youtube {
  &:before {
    content: $fa-youtube;
  }
}
.fa-xing {
  &:before {
    content: $fa-xing;
  }
}
.fa-xing-square {
  &:before {
    content: $fa-xing-square;
  }
}
.fa-youtube-play {
  &:before {
    content: $fa-youtube-play;
  }
}
.fa-dropbox {
  &:before {
    content: $fa-dropbox;
  }
}
.fa-stack-overflow {
  &:before {
    content: $fa-stack-overflow;
  }
}
.fa-instagram {
  &:before {
    content: $fa-instagram;
  }
}
.fa-flickr {
  &:before {
    content: $fa-flickr;
  }
}
.fa-adn {
  &:before {
    content: $fa-adn;
  }
}
.fa-bitbucket {
  &:before {
    content: $fa-bitbucket;
  }
}
.fa-bitbucket-square {
  &:before {
    content: $fa-bitbucket-square;
  }
}
.fa-tumblr {
  &:before {
    content: $fa-tumblr;
  }
}
.fa-tumblr-square {
  &:before {
    content: $fa-tumblr-square;
  }
}
.fa-long-arrow-down {
  &:before {
    content: $fa-long-arrow-down;
  }
}
.fa-long-arrow-up {
  &:before {
    content: $fa-long-arrow-up;
  }
}
.fa-long-arrow-left {
  &:before {
    content: $fa-long-arrow-left;
  }
}
.fa-long-arrow-right {
  &:before {
    content: $fa-long-arrow-right;
  }
}
.fa-apple {
  &:before {
    content: $fa-apple;
  }
}
.fa-windows {
  &:before {
    content: $fa-windows;
  }
}
.fa-android {
  &:before {
    content: $fa-android;
  }
}
.fa-linux {
  &:before {
    content: $fa-linux;
  }
}
.fa-dribbble {
  &:before {
    content: $fa-dribbble;
  }
}
.fa-skype {
  &:before {
    content: $fa-skype;
  }
}
.fa-foursquare {
  &:before {
    content: $fa-foursquare;
  }
}
.fa-trello {
  &:before {
    content: $fa-trello;
  }
}
.fa-female {
  &:before {
    content: $fa-female;
  }
}
.fa-male {
  &:before {
    content: $fa-male;
  }
}
.fa-gittip {
  &:before {
    content: $fa-gittip;
  }
}
.fa-gratipay {
  &:before {
    content: $fa-gratipay;
  }
}
.fa-sun-o {
  &:before {
    content: $fa-sun-o;
  }
}
.fa-moon-o {
  &:before {
    content: $fa-moon-o;
  }
}
.fa-archive {
  &:before {
    content: $fa-archive;
  }
}
.fa-bug {
  &:before {
    content: $fa-bug;
  }
}
.fa-vk {
  &:before {
    content: $fa-vk;
  }
}
.fa-weibo {
  &:before {
    content: $fa-weibo;
  }
}
.fa-renren {
  &:before {
    content: $fa-renren;
  }
}
.fa-pagelines {
  &:before {
    content: $fa-pagelines;
  }
}
.fa-stack-exchange {
  &:before {
    content: $fa-stack-exchange;
  }
}
.fa-arrow-circle-o-right {
  &:before {
    content: $fa-arrow-circle-o-right;
  }
}
.fa-arrow-circle-o-left {
  &:before {
    content: $fa-arrow-circle-o-left;
  }
}
.fa-caret-square-o-left {
  &:before {
    content: $fa-caret-square-o-left;
  }
}
.fa-toggle-left {
  &:before {
    content: $fa-toggle-left;
  }
}
.fa-dot-circle-o {
  &:before {
    content: $fa-dot-circle-o;
  }
}
.fa-wheelchair {
  &:before {
    content: $fa-wheelchair;
  }
}
.fa-vimeo-square {
  &:before {
    content: $fa-vimeo-square;
  }
}
.fa-try {
  &:before {
    content: $fa-try;
  }
}
.fa-turkish-lira {
  &:before {
    content: $fa-turkish-lira;
  }
}
.fa-plus-square-o {
  &:before {
    content: $fa-plus-square-o;
  }
}
.fa-space-shuttle {
  &:before {
    content: $fa-space-shuttle;
  }
}
.fa-slack {
  &:before {
    content: $fa-slack;
  }
}
.fa-envelope-square {
  &:before {
    content: $fa-envelope-square;
  }
}
.fa-wordpress {
  &:before {
    content: $fa-wordpress;
  }
}
.fa-openid {
  &:before {
    content: $fa-openid;
  }
}
.fa-bank {
  &:before {
    content: $fa-bank;
  }
}
.fa-institution {
  &:before {
    content: $fa-institution;
  }
}
.fa-university {
  &:before {
    content: $fa-university;
  }
}
.fa-graduation-cap {
  &:before {
    content: $fa-graduation-cap;
  }
}
.fa-mortar-board {
  &:before {
    content: $fa-mortar-board;
  }
}
.fa-yahoo {
  &:before {
    content: $fa-yahoo;
  }
}
.fa-google {
  &:before {
    content: $fa-google;
  }
}
.fa-reddit {
  &:before {
    content: $fa-reddit;
  }
}
.fa-reddit-square {
  &:before {
    content: $fa-reddit-square;
  }
}
.fa-stumbleupon-circle {
  &:before {
    content: $fa-stumbleupon-circle;
  }
}
.fa-stumbleupon {
  &:before {
    content: $fa-stumbleupon;
  }
}
.fa-delicious {
  &:before {
    content: $fa-delicious;
  }
}
.fa-digg {
  &:before {
    content: $fa-digg;
  }
}
.fa-pied-piper-pp {
  &:before {
    content: $fa-pied-piper-pp;
  }
}
.fa-pied-piper-alt {
  &:before {
    content: $fa-pied-piper-alt;
  }
}
.fa-drupal {
  &:before {
    content: $fa-drupal;
  }
}
.fa-joomla {
  &:before {
    content: $fa-joomla;
  }
}
.fa-language {
  &:before {
    content: $fa-language;
  }
}
.fa-fax {
  &:before {
    content: $fa-fax;
  }
}
.fa-building {
  &:before {
    content: $fa-building;
  }
}
.fa-child {
  &:before {
    content: $fa-child;
  }
}
.fa-paw {
  &:before {
    content: $fa-paw;
  }
}
.fa-spoon {
  &:before {
    content: $fa-spoon;
  }
}
.fa-cube {
  &:before {
    content: $fa-cube;
  }
}
.fa-cubes {
  &:before {
    content: $fa-cubes;
  }
}
.fa-behance {
  &:before {
    content: $fa-behance;
  }
}
.fa-behance-square {
  &:before {
    content: $fa-behance-square;
  }
}
.fa-steam {
  &:before {
    content: $fa-steam;
  }
}
.fa-steam-square {
  &:before {
    content: $fa-steam-square;
  }
}
.fa-recycle {
  &:before {
    content: $fa-recycle;
  }
}
.fa-automobile {
  &:before {
    content: $fa-automobile;
  }
}
.fa-car {
  &:before {
    content: $fa-car;
  }
}
.fa-cab {
  &:before {
    content: $fa-cab;
  }
}
.fa-taxi {
  &:before {
    content: $fa-taxi;
  }
}
.fa-tree {
  &:before {
    content: $fa-tree;
  }
}
.fa-spotify {
  &:before {
    content: $fa-spotify;
  }
}
.fa-deviantart {
  &:before {
    content: $fa-deviantart;
  }
}
.fa-soundcloud {
  &:before {
    content: $fa-soundcloud;
  }
}
.fa-database {
  &:before {
    content: $fa-database;
  }
}
.fa-file-pdf-o {
  &:before {
    content: $fa-file-pdf-o;
  }
}
.fa-file-word-o {
  &:before {
    content: $fa-file-word-o;
  }
}
.fa-file-excel-o {
  &:before {
    content: $fa-file-excel-o;
  }
}
.fa-file-powerpoint-o {
  &:before {
    content: $fa-file-powerpoint-o;
  }
}
.fa-file-image-o {
  &:before {
    content: $fa-file-image-o;
  }
}
.fa-file-photo-o {
  &:before {
    content: $fa-file-photo-o;
  }
}
.fa-file-picture-o {
  &:before {
    content: $fa-file-picture-o;
  }
}
.fa-file-archive-o {
  &:before {
    content: $fa-file-archive-o;
  }
}
.fa-file-zip-o {
  &:before {
    content: $fa-file-zip-o;
  }
}
.fa-file-audio-o {
  &:before {
    content: $fa-file-audio-o;
  }
}
.fa-file-sound-o {
  &:before {
    content: $fa-file-sound-o;
  }
}
.fa-file-movie-o {
  &:before {
    content: $fa-file-movie-o;
  }
}
.fa-file-video-o {
  &:before {
    content: $fa-file-video-o;
  }
}
.fa-file-code-o {
  &:before {
    content: $fa-file-code-o;
  }
}
.fa-vine {
  &:before {
    content: $fa-vine;
  }
}
.fa-codepen {
  &:before {
    content: $fa-codepen;
  }
}
.fa-jsfiddle {
  &:before {
    content: $fa-jsfiddle;
  }
}
.fa-life-bouy {
  &:before {
    content: $fa-life-bouy;
  }
}
.fa-life-buoy {
  &:before {
    content: $fa-life-buoy;
  }
}
.fa-life-ring {
  &:before {
    content: $fa-life-ring;
  }
}
.fa-life-saver {
  &:before {
    content: $fa-life-saver;
  }
}
.fa-support {
  &:before {
    content: $fa-support;
  }
}
.fa-circle-o-notch {
  &:before {
    content: $fa-circle-o-notch;
  }
}
.fa-ra {
  &:before {
    content: $fa-ra;
  }
}
.fa-rebel {
  &:before {
    content: $fa-rebel;
  }
}
.fa-resistance {
  &:before {
    content: $fa-resistance;
  }
}
.fa-empire {
  &:before {
    content: $fa-empire;
  }
}
.fa-ge {
  &:before {
    content: $fa-ge;
  }
}
.fa-git-square {
  &:before {
    content: $fa-git-square;
  }
}
.fa-git {
  &:before {
    content: $fa-git;
  }
}
.fa-hacker-news {
  &:before {
    content: $fa-hacker-news;
  }
}
.fa-y-combinator-square {
  &:before {
    content: $fa-y-combinator-square;
  }
}
.fa-yc-square {
  &:before {
    content: $fa-yc-square;
  }
}
.fa-tencent-weibo {
  &:before {
    content: $fa-tencent-weibo;
  }
}
.fa-qq {
  &:before {
    content: $fa-qq;
  }
}
.fa-wechat {
  &:before {
    content: $fa-wechat;
  }
}
.fa-weixin {
  &:before {
    content: $fa-weixin;
  }
}
.fa-paper-plane {
  &:before {
    content: $fa-paper-plane;
  }
}
.fa-send {
  &:before {
    content: $fa-send;
  }
}
.fa-paper-plane-o {
  &:before {
    content: $fa-paper-plane-o;
  }
}
.fa-send-o {
  &:before {
    content: $fa-send-o;
  }
}
.fa-history {
  &:before {
    content: $fa-history;
  }
}
.fa-circle-thin {
  &:before {
    content: $fa-circle-thin;
  }
}
.fa-header {
  &:before {
    content: $fa-header;
  }
}
.fa-paragraph {
  &:before {
    content: $fa-paragraph;
  }
}
.fa-sliders {
  &:before {
    content: $fa-sliders;
  }
}
.fa-share-alt {
  &:before {
    content: $fa-share-alt;
  }
}
.fa-share-alt-square {
  &:before {
    content: $fa-share-alt-square;
  }
}
.fa-bomb {
  &:before {
    content: $fa-bomb;
  }
}
.fa-futbol-o {
  &:before {
    content: $fa-futbol-o;
  }
}
.fa-soccer-ball-o {
  &:before {
    content: $fa-soccer-ball-o;
  }
}
.fa-tty {
  &:before {
    content: $fa-tty;
  }
}
.fa-binoculars {
  &:before {
    content: $fa-binoculars;
  }
}
.fa-plug {
  &:before {
    content: $fa-plug;
  }
}
.fa-slideshare {
  &:before {
    content: $fa-slideshare;
  }
}
.fa-twitch {
  &:before {
    content: $fa-twitch;
  }
}
.fa-yelp {
  &:before {
    content: $fa-yelp;
  }
}
.fa-newspaper-o {
  &:before {
    content: $fa-newspaper-o;
  }
}
.fa-wifi {
  &:before {
    content: $fa-wifi;
  }
}
.fa-calculator {
  &:before {
    content: $fa-calculator;
  }
}
.fa-paypal {
  &:before {
    content: $fa-paypal;
  }
}
.fa-google-wallet {
  &:before {
    content: $fa-google-wallet;
  }
}
.fa-cc-visa {
  &:before {
    content: $fa-cc-visa;
  }
}
.fa-cc-mastercard {
  &:before {
    content: $fa-cc-mastercard;
  }
}
.fa-cc-discover {
  &:before {
    content: $fa-cc-discover;
  }
}
.fa-cc-amex {
  &:before {
    content: $fa-cc-amex;
  }
}
.fa-cc-paypal {
  &:before {
    content: $fa-cc-paypal;
  }
}
.fa-cc-stripe {
  &:before {
    content: $fa-cc-stripe;
  }
}
.fa-bell-slash {
  &:before {
    content: $fa-bell-slash;
  }
}
.fa-bell-slash-o {
  &:before {
    content: $fa-bell-slash-o;
  }
}
.fa-trash {
  &:before {
    content: $fa-trash;
  }
}
.fa-copyright {
  &:before {
    content: $fa-copyright;
  }
}
.fa-at {
  &:before {
    content: $fa-at;
  }
}
.fa-eyedropper {
  &:before {
    content: $fa-eyedropper;
  }
}
.fa-paint-brush {
  &:before {
    content: $fa-paint-brush;
  }
}
.fa-birthday-cake {
  &:before {
    content: $fa-birthday-cake;
  }
}
.fa-area-chart {
  &:before {
    content: $fa-area-chart;
  }
}
.fa-pie-chart {
  &:before {
    content: $fa-pie-chart;
  }
}
.fa-line-chart {
  &:before {
    content: $fa-line-chart;
  }
}
.fa-lastfm {
  &:before {
    content: $fa-lastfm;
  }
}
.fa-lastfm-square {
  &:before {
    content: $fa-lastfm-square;
  }
}
.fa-toggle-off {
  &:before {
    content: $fa-toggle-off;
  }
}
.fa-toggle-on {
  &:before {
    content: $fa-toggle-on;
  }
}
.fa-bicycle {
  &:before {
    content: $fa-bicycle;
  }
}
.fa-bus {
  &:before {
    content: $fa-bus;
  }
}
.fa-ioxhost {
  &:before {
    content: $fa-ioxhost;
  }
}
.fa-angellist {
  &:before {
    content: $fa-angellist;
  }
}
.fa-cc {
  &:before {
    content: $fa-cc;
  }
}
.fa-ils {
  &:before {
    content: $fa-ils;
  }
}
.fa-shekel {
  &:before {
    content: $fa-shekel;
  }
}
.fa-sheqel {
  &:before {
    content: $fa-sheqel;
  }
}
.fa-meanpath {
  &:before {
    content: $fa-meanpath;
  }
}
.fa-buysellads {
  &:before {
    content: $fa-buysellads;
  }
}
.fa-connectdevelop {
  &:before {
    content: $fa-connectdevelop;
  }
}
.fa-dashcube {
  &:before {
    content: $fa-dashcube;
  }
}
.fa-forumbee {
  &:before {
    content: $fa-forumbee;
  }
}
.fa-leanpub {
  &:before {
    content: $fa-leanpub;
  }
}
.fa-sellsy {
  &:before {
    content: $fa-sellsy;
  }
}
.fa-shirtsinbulk {
  &:before {
    content: $fa-shirtsinbulk;
  }
}
.fa-simplybuilt {
  &:before {
    content: $fa-simplybuilt;
  }
}
.fa-skyatlas {
  &:before {
    content: $fa-skyatlas;
  }
}
.fa-cart-plus {
  &:before {
    content: $fa-cart-plus;
  }
}
.fa-cart-arrow-down {
  &:before {
    content: $fa-cart-arrow-down;
  }
}
.fa-diamond {
  &:before {
    content: $fa-diamond;
  }
}
.fa-ship {
  &:before {
    content: $fa-ship;
  }
}
.fa-user-secret {
  &:before {
    content: $fa-user-secret;
  }
}
.fa-motorcycle {
  &:before {
    content: $fa-motorcycle;
  }
}
.fa-street-view {
  &:before {
    content: $fa-street-view;
  }
}
.fa-heartbeat {
  &:before {
    content: $fa-heartbeat;
  }
}
.fa-venus {
  &:before {
    content: $fa-venus;
  }
}
.fa-mars {
  &:before {
    content: $fa-mars;
  }
}
.fa-mercury {
  &:before {
    content: $fa-mercury;
  }
}
.fa-intersex {
  &:before {
    content: $fa-intersex;
  }
}
.fa-transgender {
  &:before {
    content: $fa-transgender;
  }
}
.fa-transgender-alt {
  &:before {
    content: $fa-transgender-alt;
  }
}
.fa-venus-double {
  &:before {
    content: $fa-venus-double;
  }
}
.fa-mars-double {
  &:before {
    content: $fa-mars-double;
  }
}
.fa-venus-mars {
  &:before {
    content: $fa-venus-mars;
  }
}
.fa-mars-stroke {
  &:before {
    content: $fa-mars-stroke;
  }
}
.fa-mars-stroke-v {
  &:before {
    content: $fa-mars-stroke-v;
  }
}
.fa-mars-stroke-h {
  &:before {
    content: $fa-mars-stroke-h;
  }
}
.fa-neuter {
  &:before {
    content: $fa-neuter;
  }
}
.fa-genderless {
  &:before {
    content: $fa-genderless;
  }
}
.fa-facebook-official {
  &:before {
    content: $fa-facebook-official;
  }
}
.fa-pinterest-p {
  &:before {
    content: $fa-pinterest-p;
  }
}
.fa-whatsapp {
  &:before {
    content: $fa-whatsapp;
  }
}
.fa-server {
  &:before {
    content: $fa-server;
  }
}
.fa-user-plus {
  &:before {
    content: $fa-user-plus;
  }
}
.fa-user-times {
  &:before {
    content: $fa-user-times;
  }
}
.fa-bed {
  &:before {
    content: $fa-bed;
  }
}
.fa-hotel {
  &:before {
    content: $fa-hotel;
  }
}
.fa-viacoin {
  &:before {
    content: $fa-viacoin;
  }
}
.fa-train {
  &:before {
    content: $fa-train;
  }
}
.fa-subway {
  &:before {
    content: $fa-subway;
  }
}
.fa-medium {
  &:before {
    content: $fa-medium;
  }
}
.fa-y-combinator {
  &:before {
    content: $fa-y-combinator;
  }
}
.fa-yc {
  &:before {
    content: $fa-yc;
  }
}
.fa-optin-monster {
  &:before {
    content: $fa-optin-monster;
  }
}
.fa-opencart {
  &:before {
    content: $fa-opencart;
  }
}
.fa-expeditedssl {
  &:before {
    content: $fa-expeditedssl;
  }
}
.fa-battery {
  &:before {
    content: $fa-battery;
  }
}
.fa-battery-4 {
  &:before {
    content: $fa-battery-4;
  }
}
.fa-battery-full {
  &:before {
    content: $fa-battery-full;
  }
}
.fa-battery-3 {
  &:before {
    content: $fa-battery-3;
  }
}
.fa-battery-three-quarters {
  &:before {
    content: $fa-battery-three-quarters;
  }
}
.fa-battery-2 {
  &:before {
    content: $fa-battery-2;
  }
}
.fa-battery-half {
  &:before {
    content: $fa-battery-half;
  }
}
.fa-battery-1 {
  &:before {
    content: $fa-battery-1;
  }
}
.fa-battery-quarter {
  &:before {
    content: $fa-battery-quarter;
  }
}
.fa-battery-0 {
  &:before {
    content: $fa-battery-0;
  }
}
.fa-battery-empty {
  &:before {
    content: $fa-battery-empty;
  }
}
.fa-mouse-pointer {
  &:before {
    content: $fa-mouse-pointer;
  }
}
.fa-i-cursor {
  &:before {
    content: $fa-i-cursor;
  }
}
.fa-object-group {
  &:before {
    content: $fa-object-group;
  }
}
.fa-object-ungroup {
  &:before {
    content: $fa-object-ungroup;
  }
}
.fa-sticky-note {
  &:before {
    content: $fa-sticky-note;
  }
}
.fa-sticky-note-o {
  &:before {
    content: $fa-sticky-note-o;
  }
}
.fa-cc-jcb {
  &:before {
    content: $fa-cc-jcb;
  }
}
.fa-cc-diners-club {
  &:before {
    content: $fa-cc-diners-club;
  }
}
.fa-clone {
  &:before {
    content: $fa-clone;
  }
}
.fa-balance-scale {
  &:before {
    content: $fa-balance-scale;
  }
}
.fa-hourglass-o {
  &:before {
    content: $fa-hourglass-o;
  }
}
.fa-hourglass-1 {
  &:before {
    content: $fa-hourglass-1;
  }
}
.fa-hourglass-start {
  &:before {
    content: $fa-hourglass-start;
  }
}
.fa-hourglass-2 {
  &:before {
    content: $fa-hourglass-2;
  }
}
.fa-hourglass-half {
  &:before {
    content: $fa-hourglass-half;
  }
}
.fa-hourglass-3 {
  &:before {
    content: $fa-hourglass-3;
  }
}
.fa-hourglass-end {
  &:before {
    content: $fa-hourglass-end;
  }
}
.fa-hourglass {
  &:before {
    content: $fa-hourglass;
  }
}
.fa-hand-grab-o {
  &:before {
    content: $fa-hand-grab-o;
  }
}
.fa-hand-rock-o {
  &:before {
    content: $fa-hand-rock-o;
  }
}
.fa-hand-paper-o {
  &:before {
    content: $fa-hand-paper-o;
  }
}
.fa-hand-stop-o {
  &:before {
    content: $fa-hand-stop-o;
  }
}
.fa-hand-scissors-o {
  &:before {
    content: $fa-hand-scissors-o;
  }
}
.fa-hand-lizard-o {
  &:before {
    content: $fa-hand-lizard-o;
  }
}
.fa-hand-spock-o {
  &:before {
    content: $fa-hand-spock-o;
  }
}
.fa-hand-pointer-o {
  &:before {
    content: $fa-hand-pointer-o;
  }
}
.fa-hand-peace-o {
  &:before {
    content: $fa-hand-peace-o;
  }
}
.fa-trademark {
  &:before {
    content: $fa-trademark;
  }
}
.fa-registered {
  &:before {
    content: $fa-registered;
  }
}
.fa-creative-commons {
  &:before {
    content: $fa-creative-commons;
  }
}
.fa-gg {
  &:before {
    content: $fa-gg;
  }
}
.fa-gg-circle {
  &:before {
    content: $fa-gg-circle;
  }
}
.fa-tripadvisor {
  &:before {
    content: $fa-tripadvisor;
  }
}
.fa-odnoklassniki {
  &:before {
    content: $fa-odnoklassniki;
  }
}
.fa-odnoklassniki-square {
  &:before {
    content: $fa-odnoklassniki-square;
  }
}
.fa-get-pocket {
  &:before {
    content: $fa-get-pocket;
  }
}
.fa-wikipedia-w {
  &:before {
    content: $fa-wikipedia-w;
  }
}
.fa-safari {
  &:before {
    content: $fa-safari;
  }
}
.fa-chrome {
  &:before {
    content: $fa-chrome;
  }
}
.fa-firefox {
  &:before {
    content: $fa-firefox;
  }
}
.fa-opera {
  &:before {
    content: $fa-opera;
  }
}
.fa-internet-explorer {
  &:before {
    content: $fa-internet-explorer;
  }
}
.fa-television {
  &:before {
    content: $fa-television;
  }
}
.fa-tv {
  &:before {
    content: $fa-tv;
  }
}
.fa-contao {
  &:before {
    content: $fa-contao;
  }
}
.fa-500px {
  &:before {
    content: $fa-500px;
  }
}
.fa-amazon {
  &:before {
    content: $fa-amazon;
  }
}
.fa-calendar-plus-o {
  &:before {
    content: $fa-calendar-plus-o;
  }
}
.fa-calendar-minus-o {
  &:before {
    content: $fa-calendar-minus-o;
  }
}
.fa-calendar-times-o {
  &:before {
    content: $fa-calendar-times-o;
  }
}
.fa-calendar-check-o {
  &:before {
    content: $fa-calendar-check-o;
  }
}
.fa-industry {
  &:before {
    content: $fa-industry;
  }
}
.fa-map-pin {
  &:before {
    content: $fa-map-pin;
  }
}
.fa-map-signs {
  &:before {
    content: $fa-map-signs;
  }
}
.fa-map-o {
  &:before {
    content: $fa-map-o;
  }
}
.fa-map {
  &:before {
    content: $fa-map;
  }
}
.fa-commenting {
  &:before {
    content: $fa-commenting;
  }
}
.fa-commenting-o {
  &:before {
    content: $fa-commenting-o;
  }
}
.fa-houzz {
  &:before {
    content: $fa-houzz;
  }
}
.fa-vimeo {
  &:before {
    content: $fa-vimeo;
  }
}
.fa-black-tie {
  &:before {
    content: $fa-black-tie;
  }
}
.fa-fonticons {
  &:before {
    content: $fa-fonticons;
  }
}
.fa-reddit-alien {
  &:before {
    content: $fa-reddit-alien;
  }
}
.fa-edge {
  &:before {
    content: $fa-edge;
  }
}
.fa-credit-card-alt {
  &:before {
    content: $fa-credit-card-alt;
  }
}
.fa-codiepie {
  &:before {
    content: $fa-codiepie;
  }
}
.fa-modx {
  &:before {
    content: $fa-modx;
  }
}
.fa-fort-awesome {
  &:before {
    content: $fa-fort-awesome;
  }
}
.fa-usb {
  &:before {
    content: $fa-usb;
  }
}
.fa-product-hunt {
  &:before {
    content: $fa-product-hunt;
  }
}
.fa-mixcloud {
  &:before {
    content: $fa-mixcloud;
  }
}
.fa-scribd {
  &:before {
    content: $fa-scribd;
  }
}
.fa-pause-circle {
  &:before {
    content: $fa-pause-circle;
  }
}
.fa-pause-circle-o {
  &:before {
    content: $fa-pause-circle-o;
  }
}
.fa-stop-circle {
  &:before {
    content: $fa-stop-circle;
  }
}
.fa-stop-circle-o {
  &:before {
    content: $fa-stop-circle-o;
  }
}
.fa-shopping-bag {
  &:before {
    content: $fa-shopping-bag;
  }
}
.fa-shopping-basket {
  &:before {
    content: $fa-shopping-basket;
  }
}
.fa-hashtag {
  &:before {
    content: $fa-hashtag;
  }
}
.fa-bluetooth {
  &:before {
    content: $fa-bluetooth;
  }
}
.fa-bluetooth-b {
  &:before {
    content: $fa-bluetooth-b;
  }
}
.fa-percent {
  &:before {
    content: $fa-percent;
  }
}
.fa-gitlab {
  &:before {
    content: $fa-gitlab;
  }
}
.fa-wpbeginner {
  &:before {
    content: $fa-wpbeginner;
  }
}
.fa-wpforms {
  &:before {
    content: $fa-wpforms;
  }
}
.fa-envira {
  &:before {
    content: $fa-envira;
  }
}
.fa-universal-access {
  &:before {
    content: $fa-universal-access;
  }
}
.fa-wheelchair-alt {
  &:before {
    content: $fa-wheelchair-alt;
  }
}
.fa-question-circle-o {
  &:before {
    content: $fa-question-circle-o;
  }
}
.fa-blind {
  &:before {
    content: $fa-blind;
  }
}
.fa-audio-description {
  &:before {
    content: $fa-audio-description;
  }
}
.fa-volume-control-phone {
  &:before {
    content: $fa-volume-control-phone;
  }
}
.fa-braille {
  &:before {
    content: $fa-braille;
  }
}
.fa-assistive-listening-systems {
  &:before {
    content: $fa-assistive-listening-systems;
  }
}
.fa-american-sign-language-interpreting {
  &:before {
    content: $fa-american-sign-language-interpreting;
  }
}
.fa-asl-interpreting {
  &:before {
    content: $fa-asl-interpreting;
  }
}
.fa-deaf {
  &:before {
    content: $fa-deaf;
  }
}
.fa-deafness {
  &:before {
    content: $fa-deafness;
  }
}
.fa-hard-of-hearing {
  &:before {
    content: $fa-hard-of-hearing;
  }
}
.fa-glide {
  &:before {
    content: $fa-glide;
  }
}
.fa-glide-g {
  &:before {
    content: $fa-glide-g;
  }
}
.fa-sign-language {
  &:before {
    content: $fa-sign-language;
  }
}
.fa-signing {
  &:before {
    content: $fa-signing;
  }
}
.fa-low-vision {
  &:before {
    content: $fa-low-vision;
  }
}
.fa-viadeo {
  &:before {
    content: $fa-viadeo;
  }
}
.fa-viadeo-square {
  &:before {
    content: $fa-viadeo-square;
  }
}
.fa-snapchat {
  &:before {
    content: $fa-snapchat;
  }
}
.fa-snapchat-ghost {
  &:before {
    content: $fa-snapchat-ghost;
  }
}
.fa-snapchat-square {
  &:before {
    content: $fa-snapchat-square;
  }
}
.fa-pied-piper {
  &:before {
    content: $fa-pied-piper;
  }
}
.fa-first-order {
  &:before {
    content: $fa-first-order;
  }
}
.fa-yoast {
  &:before {
    content: $fa-yoast;
  }
}
.fa-themeisle {
  &:before {
    content: $fa-themeisle;
  }
}
.fa-google-plus-circle {
  &:before {
    content: $fa-google-plus-circle;
  }
}
.fa-google-plus-official {
  &:before {
    content: $fa-google-plus-official;
  }
}
.fa-fa {
  &:before {
    content: $fa-fa;
  }
}
.fa-font-awesome {
  &:before {
    content: $fa-font-awesome;
  }
}
.fa-handshake-o {
  &:before {
    content: $fa-handshake-o;
  }
}
.fa-envelope-open {
  &:before {
    content: $fa-envelope-open;
  }
}
.fa-envelope-open-o {
  &:before {
    content: $fa-envelope-open-o;
  }
}
.fa-linode {
  &:before {
    content: $fa-linode;
  }
}
.fa-address-book {
  &:before {
    content: $fa-address-book;
  }
}
.fa-address-book-o {
  &:before {
    content: $fa-address-book-o;
  }
}
.fa-address-card {
  &:before {
    content: $fa-address-card;
  }
}
.fa-vcard {
  &:before {
    content: $fa-vcard;
  }
}
.fa-address-card-o {
  &:before {
    content: $fa-address-card-o;
  }
}
.fa-vcard-o {
  &:before {
    content: $fa-vcard-o;
  }
}
.fa-user-circle {
  &:before {
    content: $fa-user-circle;
  }
}
.fa-user-circle-o {
  &:before {
    content: $fa-user-circle-o;
  }
}
.fa-user-o {
  &:before {
    content: $fa-user-o;
  }
}
.fa-id-badge {
  &:before {
    content: $fa-id-badge;
  }
}
.fa-drivers-license {
  &:before {
    content: $fa-drivers-license;
  }
}
.fa-id-card {
  &:before {
    content: $fa-id-card;
  }
}
.fa-drivers-license-o {
  &:before {
    content: $fa-drivers-license-o;
  }
}
.fa-id-card-o {
  &:before {
    content: $fa-id-card-o;
  }
}
.fa-quora {
  &:before {
    content: $fa-quora;
  }
}
.fa-free-code-camp {
  &:before {
    content: $fa-free-code-camp;
  }
}
.fa-telegram {
  &:before {
    content: $fa-telegram;
  }
}
.fa-thermometer {
  &:before {
    content: $fa-thermometer;
  }
}
.fa-thermometer-4 {
  &:before {
    content: $fa-thermometer-4;
  }
}
.fa-thermometer-full {
  &:before {
    content: $fa-thermometer-full;
  }
}
.fa-thermometer-3 {
  &:before {
    content: $fa-thermometer-3;
  }
}
.fa-thermometer-three-quarters {
  &:before {
    content: $fa-thermometer-three-quarters;
  }
}
.fa-thermometer-2 {
  &:before {
    content: $fa-thermometer-2;
  }
}
.fa-thermometer-half {
  &:before {
    content: $fa-thermometer-half;
  }
}
.fa-thermometer-1 {
  &:before {
    content: $fa-thermometer-1;
  }
}
.fa-thermometer-quarter {
  &:before {
    content: $fa-thermometer-quarter;
  }
}
.fa-thermometer-0 {
  &:before {
    content: $fa-thermometer-0;
  }
}
.fa-thermometer-empty {
  &:before {
    content: $fa-thermometer-empty;
  }
}
.fa-shower {
  &:before {
    content: $fa-shower;
  }
}
.fa-bath {
  &:before {
    content: $fa-bath;
  }
}
.fa-bathtub {
  &:before {
    content: $fa-bathtub;
  }
}
.fa-s15 {
  &:before {
    content: $fa-s15;
  }
}
.fa-podcast {
  &:before {
    content: $fa-podcast;
  }
}
.fa-window-maximize {
  &:before {
    content: $fa-window-maximize;
  }
}
.fa-window-minimize {
  &:before {
    content: $fa-window-minimize;
  }
}
.fa-window-restore {
  &:before {
    content: $fa-window-restore;
  }
}
.fa-times-rectangle {
  &:before {
    content: $fa-times-rectangle;
  }
}
.fa-window-close {
  &:before {
    content: $fa-window-close;
  }
}
.fa-times-rectangle-o {
  &:before {
    content: $fa-times-rectangle-o;
  }
}
.fa-window-close-o {
  &:before {
    content: $fa-window-close-o;
  }
}
.fa-bandcamp {
  &:before {
    content: $fa-bandcamp;
  }
}
.fa-grav {
  &:before {
    content: $fa-grav;
  }
}
.fa-etsy {
  &:before {
    content: $fa-etsy;
  }
}
.fa-imdb {
  &:before {
    content: $fa-imdb;
  }
}
.fa-ravelry {
  &:before {
    content: $fa-ravelry;
  }
}
.fa-eercast {
  &:before {
    content: $fa-eercast;
  }
}
.fa-microchip {
  &:before {
    content: $fa-microchip;
  }
}
.fa-snowflake-o {
  &:before {
    content: $fa-snowflake-o;
  }
}
.fa-superpowers {
  &:before {
    content: $fa-superpowers;
  }
}
.fa-wpexplorer {
  &:before {
    content: $fa-wpexplorer;
  }
}
.fa-meetup {
  &:before {
    content: $fa-meetup;
  }
}

